import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import '../../assets/css/membresia.css';


import desarrolladores_1 from '../../assets/images/reunion_anual/membresias/desarrolladores/1.jpg';
import desarrolladores_2 from '../../assets/images/reunion_anual/membresias/desarrolladores/2.jpg';
import desarrolladores_3 from '../../assets/images/reunion_anual/membresias/desarrolladores/3.jpg';
import desarrolladores_4 from '../../assets/images/reunion_anual/membresias/desarrolladores/4.jpg';
import desarrolladores_5 from '../../assets/images/reunion_anual/membresias/desarrolladores/5.jpg';
import desarrolladores_6 from '../../assets/images/reunion_anual/membresias/desarrolladores/6.jpg';
import desarrolladores_7 from '../../assets/images/reunion_anual/membresias/desarrolladores/7.jpg';
import desarrolladores_8 from '../../assets/images/reunion_anual/membresias/desarrolladores/8.jpg';
import desarrolladores_9 from '../../assets/images/reunion_anual/membresias/desarrolladores/9.jpg';
import desarrolladores_10 from '../../assets/images/reunion_anual/membresias/desarrolladores/10.jpg';
import desarrolladores_11 from '../../assets/images/reunion_anual/membresias/desarrolladores/11.jpg';
import desarrolladores_12 from '../../assets/images/reunion_anual/membresias/desarrolladores/12.jpg';
import desarrolladores_13 from '../../assets/images/reunion_anual/membresias/desarrolladores/13.jpg';
import desarrolladores_14 from '../../assets/images/reunion_anual/membresias/desarrolladores/14.jpg';
import desarrolladores_15 from '../../assets/images/reunion_anual/membresias/desarrolladores/15.jpg';
import desarrolladores_16 from '../../assets/images/reunion_anual/membresias/desarrolladores/16.jpg';
import desarrolladores_17 from '../../assets/images/reunion_anual/membresias/desarrolladores/17.jpg';
import desarrolladores_18 from '../../assets/images/reunion_anual/membresias/desarrolladores/18.jpg';
import desarrolladores_19 from '../../assets/images/reunion_anual/membresias/desarrolladores/19.jpg';
import desarrolladores_20 from '../../assets/images/reunion_anual/membresias/desarrolladores/20.jpg';
import desarrolladores_21 from '../../assets/images/reunion_anual/membresias/desarrolladores/21.jpg';
import desarrolladores_22 from '../../assets/images/reunion_anual/membresias/desarrolladores/22.jpg';
import desarrolladores_23 from '../../assets/images/reunion_anual/membresias/desarrolladores/23.jpg';
import desarrolladores_24 from '../../assets/images/reunion_anual/membresias/desarrolladores/24.jpg';
import desarrolladores_25 from '../../assets/images/reunion_anual/membresias/desarrolladores/25.jpg';
import desarrolladores_26 from '../../assets/images/reunion_anual/membresias/desarrolladores/26.jpg';
import desarrolladores_27 from '../../assets/images/reunion_anual/membresias/desarrolladores/27.jpg';
import desarrolladores_28 from '../../assets/images/reunion_anual/membresias/desarrolladores/28.jpg';
import desarrolladores_29 from '../../assets/images/reunion_anual/membresias/desarrolladores/29.jpg';
import desarrolladores_30 from '../../assets/images/reunion_anual/membresias/desarrolladores/30.jpg';
import desarrolladores_31 from '../../assets/images/reunion_anual/membresias/desarrolladores/31.jpg';
import desarrolladores_32 from '../../assets/images/reunion_anual/membresias/desarrolladores/32.jpg';
//import desarrolladores_33 from '../../assets/images/reunion_anual/membresias/desarrolladores/33.jpg';
import desarrolladores_34 from '../../assets/images/reunion_anual/membresias/desarrolladores/34.jpg';
import desarrolladores_35 from '../../assets/images/reunion_anual/membresias/desarrolladores/35.jpg';
//import desarrolladores_36 from '../../assets/images/reunion_anual/membresias/desarrolladores/36.jpg';
import desarrolladores_37 from '../../assets/images/reunion_anual/membresias/desarrolladores/37.jpg';
import desarrolladores_38 from '../../assets/images/reunion_anual/membresias/desarrolladores/38.jpg';
import desarrolladores_39 from '../../assets/images/reunion_anual/membresias/desarrolladores/39.jpg';
import desarrolladores_40 from '../../assets/images/reunion_anual/membresias/desarrolladores/40.jpg';
import desarrolladores_41 from '../../assets/images/reunion_anual/membresias/desarrolladores/41.jpg';
import desarrolladores_42 from '../../assets/images/reunion_anual/membresias/desarrolladores/42.jpg';
import desarrolladores_43 from '../../assets/images/reunion_anual/membresias/desarrolladores/43.jpg';
import desarrolladores_44 from '../../assets/images/reunion_anual/membresias/desarrolladores/44.jpg';
import desarrolladores_45 from '../../assets/images/reunion_anual/membresias/desarrolladores/45.jpg';
import desarrolladores_46 from '../../assets/images/reunion_anual/membresias/desarrolladores/46.jpg';
import desarrolladores_47 from '../../assets/images/reunion_anual/membresias/desarrolladores/47.jpg';
import desarrolladores_48 from '../../assets/images/reunion_anual/membresias/desarrolladores/48.jpg';
import desarrolladores_49 from '../../assets/images/reunion_anual/membresias/desarrolladores/49.jpg';
import desarrolladores_50 from '../../assets/images/reunion_anual/membresias/desarrolladores/50.jpg';
import desarrolladores_51 from '../../assets/images/reunion_anual/membresias/desarrolladores/51.jpg';
import desarrolladores_52 from '../../assets/images/reunion_anual/membresias/desarrolladores/52.jpg';
import desarrolladores_53 from '../../assets/images/reunion_anual/membresias/desarrolladores/53.jpg';
import desarrolladores_54 from '../../assets/images/reunion_anual/membresias/desarrolladores/54.jpg';
import desarrolladores_55 from '../../assets/images/reunion_anual/membresias/desarrolladores/55.jpg';
import desarrolladores_56 from '../../assets/images/reunion_anual/membresias/desarrolladores/56.jpg';
import desarrolladores_57 from '../../assets/images/reunion_anual/membresias/desarrolladores/57.jpg';
import desarrolladores_58 from '../../assets/images/reunion_anual/membresias/desarrolladores/58.jpg';
import desarrolladores_59 from '../../assets/images/reunion_anual/membresias/desarrolladores/59.jpg';
import desarrolladores_60 from '../../assets/images/reunion_anual/membresias/desarrolladores/60.jpg';
import desarrolladores_61 from '../../assets/images/reunion_anual/membresias/desarrolladores/61.jpg';
import desarrolladores_62 from '../../assets/images/reunion_anual/membresias/desarrolladores/62.jpg';
import desarrolladores_63 from '../../assets/images/reunion_anual/membresias/desarrolladores/63.jpg';
import desarrolladores_64 from '../../assets/images/reunion_anual/membresias/desarrolladores/64.jpg';
import desarrolladores_65 from '../../assets/images/reunion_anual/membresias/desarrolladores/65.jpg';
import desarrolladores_66 from '../../assets/images/reunion_anual/membresias/desarrolladores/66.jpg';
import desarrolladores_67 from '../../assets/images/reunion_anual/membresias/desarrolladores/67.jpg';
import desarrolladores_68 from '../../assets/images/reunion_anual/membresias/desarrolladores/68.jpg';
import desarrolladores_69 from '../../assets/images/reunion_anual/membresias/desarrolladores/69.jpg';
import desarrolladores_70 from '../../assets/images/reunion_anual/membresias/desarrolladores/70.jpg';
import desarrolladores_71 from '../../assets/images/reunion_anual/membresias/desarrolladores/71.jpg';
//import desarrolladores_72 from '../../assets/images/reunion_anual/membresias/desarrolladores/72.jpg';
import desarrolladores_73 from '../../assets/images/reunion_anual/membresias/desarrolladores/73.jpg';
import desarrolladores_74 from '../../assets/images/reunion_anual/membresias/desarrolladores/74.jpg';
import desarrolladores_75 from '../../assets/images/reunion_anual/membresias/desarrolladores/75.jpg';
import desarrolladores_76 from '../../assets/images/reunion_anual/membresias/desarrolladores/76.png';
import desarrolladores_77 from '../../assets/images/reunion_anual/membresias/desarrolladores/77.png';
import desarrolladores_78 from '../../assets/images/reunion_anual/membresias/desarrolladores/78.png';
import desarrolladores_79 from '../../assets/images/reunion_anual/membresias/desarrolladores/79.jpg';
import desarrolladores_80 from '../../assets/images/reunion_anual/membresias/desarrolladores/80.jpg';
import desarrolladores_81 from '../../assets/images/reunion_anual/membresias/desarrolladores/81.jpg';

import fibras_1 from '../../assets/images/reunion_anual/membresias/fibras/1.jpg';
import fibras_2 from '../../assets/images/reunion_anual/membresias/fibras/2.jpg';
import fibras_3 from '../../assets/images/reunion_anual/membresias/fibras/3.jpg';
import fibras_4 from '../../assets/images/reunion_anual/membresias/fibras/4.jpg';

import fondos_1 from '../../assets/images/reunion_anual/membresias/fondos/1.jpg';
import fondos_2 from '../../assets/images/reunion_anual/membresias/fondos/2.jpg';
import fondos_3 from '../../assets/images/reunion_anual/membresias/fondos/3.jpg';
import fondos_4 from '../../assets/images/reunion_anual/membresias/fondos/4.jpg';
import fondos_5 from '../../assets/images/reunion_anual/membresias/fondos/5.jpg';
import fondos_6 from '../../assets/images/reunion_anual/membresias/fondos/6.jpg';
import fondos_7 from '../../assets/images/reunion_anual/membresias/fondos/7.jpg';
import fondos_8 from '../../assets/images/reunion_anual/membresias/fondos/8.jpg';
import fondos_9 from '../../assets/images/reunion_anual/membresias/fondos/9.jpg';

import gobierno_1 from '../../assets/images/reunion_anual/membresias/gobierno/1.jpg';
import gobierno_2 from '../../assets/images/reunion_anual/membresias/gobierno/2.jpg';

import construccion_1 from '../../assets/images/reunion_anual/membresias/construccion/1.jpg';
import construccion_2 from '../../assets/images/reunion_anual/membresias/construccion/2.jpg';
import construccion_3 from '../../assets/images/reunion_anual/membresias/construccion/3.jpg';
import construccion_4 from '../../assets/images/reunion_anual/membresias/construccion/4.jpg';
import construccion_5 from '../../assets/images/reunion_anual/membresias/construccion/5.jpg';
import construccion_6 from '../../assets/images/reunion_anual/membresias/construccion/6.jpg';
import construccion_7 from '../../assets/images/reunion_anual/membresias/construccion/7.jpg';
import construccion_8 from '../../assets/images/reunion_anual/membresias/construccion/8.jpg';
import construccion_9 from '../../assets/images/reunion_anual/membresias/construccion/9.jpg';
import construccion_10 from '../../assets/images/reunion_anual/membresias/construccion/10.jpg';
import construccion_11 from '../../assets/images/reunion_anual/membresias/construccion/11.jpg';
import construccion_12 from '../../assets/images/reunion_anual/membresias/construccion/12.jpg';
import construccion_13 from '../../assets/images/reunion_anual/membresias/construccion/13.jpg';
import construccion_14 from '../../assets/images/reunion_anual/membresias/construccion/14.jpg';
import construccion_15 from '../../assets/images/reunion_anual/membresias/construccion/15.jpg';
import construccion_16 from '../../assets/images/reunion_anual/membresias/construccion/16.jpg';
import construccion_17 from '../../assets/images/reunion_anual/membresias/construccion/17.jpg';
import construccion_18 from '../../assets/images/reunion_anual/membresias/construccion/18.jpg';
import construccion_19 from '../../assets/images/reunion_anual/membresias/construccion/19.jpg';
import construccion_20 from '../../assets/images/reunion_anual/membresias/construccion/20.jpg';
import construccion_21 from '../../assets/images/reunion_anual/membresias/construccion/21.jpg';
import construccion_22 from '../../assets/images/reunion_anual/membresias/construccion/22.jpg';
import construccion_23 from '../../assets/images/reunion_anual/membresias/construccion/23.jpg';
import construccion_24 from '../../assets/images/reunion_anual/membresias/construccion/24.png';
import construccion_25 from '../../assets/images/reunion_anual/membresias/construccion/25.jpg';
import construccion_26 from '../../assets/images/reunion_anual/membresias/construccion/26.jpg';

import energia_1 from '../../assets/images/reunion_anual/membresias/energia/1.jpg';
import energia_2 from '../../assets/images/reunion_anual/membresias/energia/2.jpg';
import energia_3 from '../../assets/images/reunion_anual/membresias/energia/3.jpg';
import energia_4 from '../../assets/images/reunion_anual/membresias/energia/4.jpg';
import energia_5 from '../../assets/images/reunion_anual/membresias/energia/5.jpg';
import energia_6 from '../../assets/images/reunion_anual/membresias/energia/6.jpg';
import energia_7 from '../../assets/images/reunion_anual/membresias/energia/7.jpg';
import energia_8 from '../../assets/images/reunion_anual/membresias/energia/8.jpg';
import energia_9 from '../../assets/images/reunion_anual/membresias/energia/9.jpg';
import energia_10 from '../../assets/images/reunion_anual/membresias/energia/10.png';

import servicios_1 from '../../assets/images/reunion_anual/membresias/consultoria/1.jpg';
import servicios_2 from '../../assets/images/reunion_anual/membresias/consultoria/2.jpg';
import servicios_3 from '../../assets/images/reunion_anual/membresias/consultoria/3.png';

import financiero_1 from '../../assets/images/reunion_anual/membresias/financieros/1.jpg';
import financiero_2 from '../../assets/images/reunion_anual/membresias/financieros/2.jpg';
import financiero_3 from '../../assets/images/reunion_anual/membresias/financieros/3.jpg';
import financiero_4 from '../../assets/images/reunion_anual/membresias/financieros/4.jpg';
import financiero_5 from '../../assets/images/reunion_anual/membresias/financieros/5.jpg';
import financiero_6 from '../../assets/images/reunion_anual/membresias/financieros/6.jpg';
import financiero_7 from '../../assets/images/reunion_anual/membresias/financieros/7.jpg';
import financiero_8 from '../../assets/images/reunion_anual/membresias/financieros/8.jpg';

import inmobiliarios_1 from '../../assets/images/reunion_anual/membresias/inmoviliario/1.jpg';
import inmobiliarios_2 from '../../assets/images/reunion_anual/membresias/inmoviliario/2.jpg';
import inmobiliarios_3 from '../../assets/images/reunion_anual/membresias/inmoviliario/3.jpg';
import inmobiliarios_4 from '../../assets/images/reunion_anual/membresias/inmoviliario/4.jpg';
import inmobiliarios_5 from '../../assets/images/reunion_anual/membresias/inmoviliario/5.jpg';
import inmobiliarios_6 from '../../assets/images/reunion_anual/membresias/inmoviliario/6.png';

import telecomunicaciones_1 from '../../assets/images/reunion_anual/membresias/telecomunicaciones/1.jpg';
import telecomunicaciones_2 from '../../assets/images/reunion_anual/membresias/telecomunicaciones/2.jpg';
import telecomunicaciones_3 from '../../assets/images/reunion_anual/membresias/telecomunicaciones/3.jpg';
import telecomunicaciones_4 from '../../assets/images/reunion_anual/membresias/telecomunicaciones/4.jpg';
import telecomunicaciones_5 from '../../assets/images/reunion_anual/membresias/telecomunicaciones/5.jpg';
import telecomunicaciones_6 from '../../assets/images/reunion_anual/membresias/telecomunicaciones/6.jpg';


import tecnologia_1 from '../../assets/images/reunion_anual/membresias/tecnologia/1.jpg';
import tecnologia_2 from '../../assets/images/reunion_anual/membresias/tecnologia/2.jpg';
import tecnologia_3 from '../../assets/images/reunion_anual/membresias/tecnologia/3.jpg';
import tecnologia_4 from '../../assets/images/reunion_anual/membresias/tecnologia/4.jpg';

let logos = [
    {tipo:1,imagen:desarrolladores_1},
{tipo:1,imagen:desarrolladores_2},
{tipo:1,imagen:desarrolladores_3},
{tipo:1,imagen:desarrolladores_4},
{tipo:1,imagen:desarrolladores_5},
{tipo:1,imagen:desarrolladores_6},
{tipo:1,imagen:desarrolladores_81},
{tipo:1,imagen:desarrolladores_7},
{tipo:1,imagen:desarrolladores_8},
{tipo:1,imagen:desarrolladores_9},
{tipo:1,imagen:desarrolladores_10},
{tipo:1,imagen:desarrolladores_11},
{tipo:1,imagen:desarrolladores_12},
{tipo:1,imagen:desarrolladores_13},
{tipo:1,imagen:desarrolladores_76},
//{tipo:1,imagen:desarrolladores_14},
{tipo:1,imagen:desarrolladores_15},
{tipo:1,imagen:desarrolladores_16},
{tipo:1,imagen:desarrolladores_17},
{tipo:1,imagen:desarrolladores_18},
{tipo:1,imagen:desarrolladores_19},
//{tipo:1,imagen:desarrolladores_70},
{tipo:1,imagen:desarrolladores_20},
{tipo:1,imagen:desarrolladores_21},
//{tipo:1,imagen:desarrolladores_22},
{tipo:1,imagen:desarrolladores_23},
{tipo:1,imagen:desarrolladores_24},
{tipo:1,imagen:desarrolladores_25},
{tipo:1,imagen:desarrolladores_26},
{tipo:1,imagen:desarrolladores_74},
{tipo:1,imagen:desarrolladores_27},
{tipo:1,imagen:desarrolladores_28},
{tipo:1,imagen:desarrolladores_29},
{tipo:1,imagen:desarrolladores_69},
{tipo:1,imagen:desarrolladores_71},
{tipo:1,imagen:desarrolladores_30},
{tipo:1,imagen:desarrolladores_77},
{tipo:1,imagen:desarrolladores_31},
{tipo:1,imagen:desarrolladores_32},
{tipo:1,imagen:desarrolladores_78},
//{tipo:1,imagen:desarrolladores_33},
{tipo:1,imagen:desarrolladores_34},
{tipo:1,imagen:desarrolladores_35},
//{tipo:1,imagen:desarrolladores_36},
{tipo:1,imagen:desarrolladores_37},
{tipo:1,imagen:desarrolladores_38},
{tipo:1,imagen:desarrolladores_39},
{tipo:1,imagen:desarrolladores_40},
{tipo:1,imagen:desarrolladores_41},
{tipo:1,imagen:desarrolladores_42},
{tipo:1,imagen:desarrolladores_43},
{tipo:1,imagen:desarrolladores_44},
{tipo:1,imagen:desarrolladores_45},
{tipo:1,imagen:desarrolladores_46},
{tipo:1,imagen:desarrolladores_47},
{tipo:1,imagen:desarrolladores_48},
{tipo:1,imagen:desarrolladores_49},
{tipo:1,imagen:desarrolladores_50},
{tipo:1,imagen:desarrolladores_51},
{tipo:1,imagen:desarrolladores_68},
{tipo:1,imagen:desarrolladores_52},
{tipo:1,imagen:desarrolladores_53},
{tipo:1,imagen:desarrolladores_54},
{tipo:1,imagen:desarrolladores_55},
{tipo:1,imagen:desarrolladores_56},
{tipo:1,imagen:desarrolladores_73},
{tipo:1,imagen:desarrolladores_57},
{tipo:1,imagen:desarrolladores_58},
{tipo:1,imagen:desarrolladores_59},
{tipo:1,imagen:desarrolladores_60},
{tipo:1,imagen:desarrolladores_61},
{tipo:1,imagen:desarrolladores_62},
//{tipo:1,imagen:desarrolladores_72},
{tipo:1,imagen:desarrolladores_75},
{tipo:1,imagen:desarrolladores_80},
{tipo:1,imagen:desarrolladores_79},
{tipo:1,imagen:desarrolladores_63},
{tipo:1,imagen:desarrolladores_64},
{tipo:1,imagen:desarrolladores_65},
{tipo:1,imagen:desarrolladores_66},
{tipo:1,imagen:desarrolladores_67},
{tipo:2,imagen:fibras_1},
{tipo:2,imagen:fibras_2},
{tipo:2,imagen:fibras_3},
{tipo:2,imagen:fibras_4},
{tipo:3,imagen:fondos_9},
{tipo:3,imagen:fondos_1},
{tipo:3,imagen:fondos_2},
{tipo:3,imagen:fondos_3},
{tipo:3,imagen:fondos_4},
{tipo:3,imagen:fondos_5},
//{tipo:3,imagen:fondos_8},
//{tipo:3,imagen:fondos_7},
{tipo:3,imagen:fondos_6},
//{tipo:4,imagen:gobierno_1},
{tipo:4,imagen:gobierno_2},
];
let logos_2 = [
{tipo:5,imagen:construccion_22},
{tipo:5,imagen:construccion_19},
{tipo:5,imagen:construccion_18},
{tipo:5,imagen:construccion_23},
{tipo:5,imagen:construccion_17},
{tipo:5,imagen:construccion_2},
//{tipo:5,imagen:construccion_3},
{tipo:5,imagen:construccion_4},
{tipo:5,imagen:construccion_20},
{tipo:5,imagen:construccion_21},
{tipo:5,imagen:construccion_5},
{tipo:5,imagen:construccion_6},
{tipo:5,imagen:construccion_24},
{tipo:5,imagen:construccion_7},
{tipo:5,imagen:construccion_26},
{tipo:5,imagen:construccion_25},
//{tipo:5,imagen:construccion_8},
{tipo:5,imagen:construccion_12},
{tipo:5,imagen:construccion_9},
//{tipo:5,imagen:construccion_10},
{tipo:5,imagen:construccion_13},
//{tipo:5,imagen:construccion_11},
{tipo:5,imagen:construccion_14},
{tipo:5,imagen:construccion_15},
{tipo:5,imagen:construccion_16},
{tipo:6,imagen:energia_1},
{tipo:6,imagen:energia_2},
{tipo:6,imagen:energia_7},
{tipo:6,imagen:energia_6},
{tipo:6,imagen:energia_10},
{tipo:6,imagen:energia_3},
{tipo:6,imagen:energia_4},
{tipo:6,imagen:energia_5},
{tipo:6,imagen:energia_9},
{tipo:6,imagen:energia_8},
{tipo:7,imagen:servicios_3},
{tipo:7,imagen:servicios_2},
{tipo:8,imagen:financiero_1},
{tipo:8,imagen:financiero_2},
{tipo:8,imagen:financiero_6},
//{tipo:8,imagen:financiero_4},
{tipo:8,imagen:financiero_8},
{tipo:8,imagen:financiero_7},
{tipo:8,imagen:financiero_5},
{tipo:9,imagen:inmobiliarios_1},
{tipo:9,imagen:inmobiliarios_2},
{tipo:9,imagen:inmobiliarios_3},
{tipo:9,imagen:inmobiliarios_4},
{tipo:9,imagen:inmobiliarios_6},
{tipo:10,imagen:telecomunicaciones_4},
{tipo:10,imagen:telecomunicaciones_1},
{tipo:10,imagen:telecomunicaciones_5},
//{tipo:10,imagen:telecomunicaciones_2},
//{tipo:10,imagen:telecomunicaciones_3},
{tipo:10,imagen:telecomunicaciones_6},
{tipo:11,imagen:tecnologia_1},
{tipo:11,imagen:tecnologia_2},
{tipo:11,imagen:tecnologia_3},
{tipo:11,imagen:tecnologia_4},
]

const Membresia = (props) => {
    const [agenda, setRevista] = useState(false);
    const { idioma } = props;
    const [ver_tipo, setVerTipo] = useState(0);

    const [ver_tipo_2, setVerTipo2] = useState(0);

    const ver_logo = logos.filter((logo)=>
    {
        if(ver_tipo == logo.tipo){
            return true;
        }
        else{
            return false;
        }
    }).map((item)=>{
        return(
            <>
            <Col xs={8} md={4}>
                <img src={item.imagen} style={{width:'100%', padding:'10px'}} />
            </Col>
            </>
        )
    })
    const ver_logo_2 = logos_2.filter((logo)=>
    {
        if(ver_tipo_2 == logo.tipo){
            return true;
        }
        else{
            return false;
        }
    }).map((item)=>{
        return(
            <>
            <Col xs={8} md={4}>
                <img src={item.imagen} style={{width:'100%', padding:'10px'}} />
            </Col>
            </>
        )
    })
    
    return (
        <Row className='back_membresia_home'>
            <Col xs={{span:24, offset:0}} md={{span:20, offset:2}}>
                <Row justify='center' align='middle' style={{ position: '' }}>
                    <Col span={24} offset={0} style={{ position: '', width: '100%' }}>
                        <p className="textos_titulos_secciones color_gris_oscuro">MEMBRESÍA</p>
                    </Col>
                </Row>
            </Col>

            <Col span={20} offset={2}>
                <Row justify='space-around' align='middle'>
                    <Col xs={20} md={5} className={ver_tipo === 1 ? 'back_gris_membresia_select margin_chico' : 'back_gris_membresia margin_chico'} onClick={()=>setVerTipo(1)}>
                        <div className='dvi'>
                        <p>Desarrolladores<br /> inmobiliarios</p>
                        </div>
                    </Col>
                    <Col xs={20} md={5} className={ver_tipo === 2 ? 'back_gris_membresia_select margin_chico' : 'back_gris_membresia margin_chico'} onClick={()=>setVerTipo(2)}>
                        <div className='dvi'>
                            <p>FIBRAS</p>
                        </div>
                    </Col>
                    <Col xs={20} md={5} className={ver_tipo === 3 ? 'back_gris_membresia_select margin_chico' : 'back_gris_membresia margin_chico'} onClick={()=>setVerTipo(3)}>
                        <div className='dvi'>
                            <p>Fondos de inversión</p>
                        </div>
                    </Col>
                    <Col xs={20} md={5} className={ver_tipo === 4 ? 'back_gris_membresia_select margin_chico' : 'back_gris_membresia margin_chico'} onClick={()=>setVerTipo(4)}>
                        <div className='dvi'>
                            <p>Fideicomisos estatales</p>
                        </div>
                    </Col>
                </Row>
                <br /><br />
            </Col>
            <Col span={24} className='back_blanco' style={{padding:'3% 0%'}}>
                <Row justify="center">
                    <Col span={20}>
                        <Row>
                        {ver_logo}
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col span={20} offset={2}>
                <br /><br />
                <Row justify='space-around'>
                    <Col xs={20} md={5} className={ver_tipo_2 === 5 ? 'back_azum_membresia_select margin_chico' : 'back_azum_membresia margin_chico'}  onClick={()=>setVerTipo2(5)}>
                        <div className='dvi'>
                            <p>Construcción</p>
                        </div>
                    </Col>
                    <Col xs={20} md={5} className={ver_tipo_2 === 6 ? 'back_azum_membresia_select margin_chico' : 'back_azum_membresia margin_chico'}  onClick={()=>setVerTipo2(6)}>
                        <div className='dvi'>
                            <p>Energía</p>
                        </div>
                    </Col>
                    <Col xs={20} md={5} className={ver_tipo_2 === 7 ? 'back_azum_membresia_select margin_chico' : 'back_azum_membresia margin_chico'} onClick={()=>setVerTipo2(7)}>
                        <div className='dvi'>
                            <p>Servicios de consultoría</p>
                        </div>
                    </Col>
                    <Col xs={20} md={5} className={ver_tipo_2 === 8 ? 'back_azum_membresia_select margin_chico' : 'back_azum_membresia margin_chico'} onClick={()=>setVerTipo2(8)}>
                        <div className='dvi'>
                            <p>Servicios financieros</p>
                        </div>
                    </Col>
                    <Col xs={0} md={24}><br /></Col>
                    <Col xs={20} md={5} className={ver_tipo_2 === 9 ? 'back_azum_membresia_select margin_chico' : 'back_azum_membresia margin_chico'} onClick={()=>setVerTipo2(9)}>
                        <div className='dvi'>
                            <p>Servicios inmobiliarios</p>
                        </div>
                    </Col>
                    <Col xs={20} md={5} className={ver_tipo_2 === 11 ? 'back_azum_membresia_select margin_chico' : 'back_azum_membresia margin_chico'} onClick={()=>setVerTipo2(11)}>
                        <div className='dvi'>
                            <p>Tecnología y seguridad</p>
                        </div>
                    </Col>
                    <Col xs={20} md={5} className={ver_tipo_2 === 10 ? 'back_azum_membresia_select margin_chico' : 'back_azum_membresia margin_chico'} onClick={()=>setVerTipo2(10)}>
                        <div className='dvi'>
                            <p>Telecomunicaciones y transportes</p>
                        </div>
                    </Col>
                </Row>
                <br /><br />
            </Col>
            <Col span={24} className='back_blanco' style={{padding:'3% 0%'}}>
                <Row justify="center">
                    <Col span={20}>
                        <Row>
                        {ver_logo_2}
                        </Row>
                    </Col>
                </Row>
            </Col>

        </Row>
    )
}
export default Membresia;