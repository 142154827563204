import React, { useState } from "react";
import { Row, Col, Modal } from "antd";
import "../../assets/css/ponentes.css";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import { CaretLeftFilled, CaretRightFilled, CloseOutlined } from "@ant-design/icons";

import ponente1 from "../../assets/images/anual_2024/ponentes/GuillermoEspinosa.jpg";
import ponente2 from "../../assets/images/anual_2024/ponentes/JorgeAvalos.jpg";
import ponente3 from "../../assets/images/anual_2024/ponentes/marceloebrad.png";
import ponente4 from "../../assets/images/anual_2024/ponentes/RodrigoPacheco.jpg";
import ponente5 from "../../assets/images/anual_2024/ponentes/LilaAbed.jpg";
import ponente6 from "../../assets/images/anual_2024/ponentes/GerardoRamirez.jpg";
import ponente7 from "../../assets/images/anual_2024/ponentes/EnriqueLavin.jpg";
import ponente8 from "../../assets/images/anual_2024/ponentes/RubenDuque.jpg";
import ponente9 from "../../assets/images/anual_2024/ponentes/MarcSelvitelli.jpg";
import ponente10 from "../../assets/images/anual_2024/ponentes/Rosanety.jpg";
import ponente11 from "../../assets/images/anual_2024/ponentes/DavidVelosa.png";
import ponente12 from "../../assets/images/anual_2024/ponentes/CarlaOrtiz.jpg";
import ponente13 from "../../assets/images/anual_2024/ponentes/AltagraciaGomez.jpg";
import ponente14 from "../../assets/images/anual_2024/ponentes/JesusMunoz.jpg";
import ponente15 from "../../assets/images/anual_2024/ponentes/EnaGutierrez.jpg";
import ponente16 from "../../assets/images/anual_2024/ponentes/victormejia.jpg";
import ponente17 from "../../assets/images/anual_2024/ponentes/AdolfoBorja.png";
import ponente18 from "../../assets/images/anual_2024/ponentes/claudiaEsteves.jpg";
import ponente19 from "../../assets/images/anual_2024/ponentes/DavidEaton.jpg";
import ponente20 from "../../assets/images/anual_2024/ponentes/SantiagoVillagomez.jpg";
import ponente21 from "../../assets/images/anual_2024/ponentes/antonioperea.jpg";
import ponente22 from "../../assets/images/anual_2024/ponentes/ponentes_LymanDaniels_ampip_CONVENCION.jpg";


const expositor = [
  //{nombre:'', img: avatar,img_hover:avatar_hover, hash:'/conferencistas#' },
  {
    nombre: "Guillermo Espinosa",
    empresa: "",
    cargo: "",
    img: ponente1,
    url: '',
    semblanza: [<> Guillermo es el jefe de desarrollo en México de DHL Supply Chain-Real Estate Solutions, una subsidiaria de DHL Group. La empresa ofrece una gama de servicios para los fabricantes de la zona, como la logística, la planificación estratégica, la consultoría de la cadena de suministro y la distribución. Cuenta con más de dos décadas de experiencia en desarrollo industrial en México.
    </>],
  },{
    nombre: "Jorge Ávalos",
    empresa: "Presidente de AMPIP",
    cargo: "",
    img: ponente2,
    url: '',
    semblanza: [<>Con más de 30 años de experiencia en el ámbito inmobiliario y financiero, Jorge Avalos es director general y cofundador de Fibra Monterrey empresa que realizó su oferta pública inicial en la Bolsa Mexicana de Valores, en diciembre del 2014 y cuya especialización es la propiedad y administración naves industriales primordialmente. 
      <br /><br />
      Es accionista y presidente de Escala. Empresa fundada en 1990 y pionera en la administración profesional de proyectos para el sector de bienes raíces en México. Actualmente, es el Presidente del Consejo Directivo de la Asociación Mexicana de Parques Industriales Privados (AMPIP).
      <br /><br />
      Tiene una trayectoria de más de 20 años como miembro de distintos consejos de administración de empresas públicas y privadas.
      <br /><br />
      Jorge es licenciado en contaduría del Instituto Tecnológico Autónomo de México (ITAM), con maestría en negocios en la Universidad de Dallas, diplomado de negocios por la Universidad de Harvard y programa de alta dirección D2 por el Instituto Panamericano de Alta Dirección (IPADE).      
      </>],
  },{
    nombre: "Marcelo Ebrard",
    empresa: "",
    cargo: "",
    img: ponente3,
    url: '',
    semblanza: [null],
  },{
    nombre: "Rodrigo Pacheco",
    empresa: "",
    cargo: "Periodista de negocios.",
    img: ponente4,
    url: '',
    semblanza: [<>Rodrigo Pacheco es un periodista de negocios, ha impartido conferencias a cámaras empresariales, líderes de negocios, empresas y universidades, con el enfoque de transmitir un análisis de México y su desenvolvimiento económico influido por factores políticos y sociales. Además aborda las grandes tendencias globales por las que navega el país en el siglo XXI. Rodrigo actualmente es el titular de Imagen Empresarial, programa de radio en donde se abordan temas del mundo corporativo, economía y finanzas, con un enfoque fresco y dinámico con un fuerte énfasis en la información internacional, de tecnología e innovación y su vinculación con la coyuntura mexicana.<br />
      Además, Rodrigo colabora en distintos espacios informativos de Grupo Imagen Multimedia y publica una columna semanal en el periódico Excélsior.<br /><br />
      Es miembro del consejo editorial de la Revista ISTMO del IPADE. Rodrigo es licenciado en Relaciones Internacionales egresado del Tecnológico de Monterrey.
    </>],
  },{
    nombre: "Gerardo Ramírez",
    empresa: "",
    cargo: "",
    img: ponente6,
    url: '',
    semblanza: [<>Gerardo has been leading the JLL Corporate Industrial Services for 15 years. With a deep knowledge of the different industrial markets in Mexico, he has helped clients from all over the world to perform projects in different cities. As an active member of different international groups, Gerardo coordinates and operates together with the 7 JLL offices in Mexico all the information and negotiations that are needed to buy or lease an industrial facility, participating in several businesses of industrial and offices (in Monterrey) related to site selection, tenant representation, opinions of value, acquisitions, dispositions, leasing, development and client advisories.<br /><br />
      Before joining JLL Mexico’s in early 2009, Mr. Ramírez worked for the Mexican Government in Ports planning; later managing two Construction Companies; and lastly as Vice President of Operations and Development in G. Acción / AMB Property Mexico (now Prologis) leading in Mexico the acquisitions, development and leasing teams.      
    </>],
  },{
    nombre: "Lila Abed",
    empresa: "",
    cargo: "",
    img: ponente5,
    url: '',
    semblanza: [<>Lila Abed es la Directora Interina del Instituto de México. Anteriormente, se desempeñó como Subdirectora. Antes de convertirse en Subdirectora del Instituto de México, Lila fue corresponsal de la Casa Blanca para el canal de noticias internacional NTN24. Como analista internacional apasionada, utiliza sus habilidades en una variedad de redes de medios, incluyendo sus roles como colaboradora permanente en Radio Fórmula y columnista semanal para El Heraldo de México y Opinión51.<br /><br />
      Lila trabajó como Asesora de Políticas Públicas para América Latina en el bufete internacional Covington & Burling en Washington, DC, después de haber sido Asesora de Asuntos Internacionales para la Comisión de Relaciones Exteriores de la Cámara de Diputados de México. También trabajó en varias instituciones públicas, incluidas las Embajadas de México en Canadá y en el Reino Unido. Lila adquirió una gran experiencia durante su tiempo en la Fiscalía General de México, donde fue Directora General de Cooperación Internacional, seguida de un período como Asesora de Asuntos Internacionales y posteriormente como Jefa de Gabinete del Fiscal General. Anteriormente ocupó el cargo de Secretaria de Asuntos Internacionales en el Comité Ejecutivo del Partido Revolucionario Institucional. Lila también se postuló para un escaño en la Cámara de Diputados de la Ciudad de México.<br /><br />
      Cuenta con una maestría en Estudios Latinoamericanos y Gobierno de la Universidad de Georgetown. Se graduó magna cum laude con una licenciatura en Estudios Internacionales y Estudios Hispánicos y una especialización en Chino en Boston College.
    </>],
  },{
    nombre: "Enrique Lavín ",
    empresa: "",
    cargo: "",
    img: ponente7,
    url: '',
    semblanza: [<>
    Enrique Lavín es Director General de México de PGIM Real Estate, donde también funge como jefe de Administración de Portafolios, Latinoamérica.<br /><br />
    Es responsable de proporcionar dirección estratégica y supervisar el desempeño financiero de los activos bajo administración de PGIM, por casi US$3,000 millones. Asimismo, Enrique es encargado de dirigir el desarrollo de nuevos negocios y por lo tanto, responsable de estructurar las iniciativas de levantamiento de capital público y privado para la región , así como de administrar las relaciones de PGIM Real Estate con bancos de inversión e inversionistas. Enrique es miembro del Comité de Inversiones de América Latina.<br /><br />
    Durante su carrera profesional en PGIM Real Estate, Enrique ha celebrado operaciones que de manera conjunta representan un total de US$6,000 millones, tanto en mercados públicos como privados, incluyendo inversiones en deuda, capital, fusiones y adquisiciones. <br /><br />
    Su experiencia laboral incluye casi 18 años en la industria de bienes raíces en México y  US$7,000 millones en transacciones en posiciones como vicepresidente de financiamiento inmobiliario y bursatilizaciones en Credit Suisse, así como director de originación de Créditos en GE Real Estate. De 2011 a 2012, Enrique fue miembro del Consejo de Administración de Paz Corp. S.A., una empresa constructora pública con sede en Chile, y actualmente es miembro del Comité de Inversiones de PGIM Real Estate México.<br /><br />
    Enrique es licenciado en Ingeniería Industrial del ITESM (campus Ciudad de México) y obtuvo una Maestría en Administración de Negocios (MBA) de la Universidad de Texas en Austin, en donde fue galardonado con el Premio Global Star.<br /><br />
    </>],
  },{
    nombre: "Rubén Duque",
    empresa: "",
    cargo: "",
    img: ponente8,
    url: '',
    semblanza: [<>
    Speaker internacional en más de 400 eventos, ha impactado a lideres y equipos en más de 10 países, incluyendo empresas del Fortune 500 como Google y Microsoft. Con más de 25 años como entrenador de rugby, ha trabajado en Nueva Zelanda y dirigido la Federación Española y Mexicana de Rugby, llevando a México a ser 5 veces campeón del Rugby Americas North.<br /><br />
    Ha colaborado con atletas olímpicos y líderes empresariales, y está certificado en cultura y liderazgo de equipos por Cambridge Business School y Disney Institute. Colaborador en El Economista y host del podcast “Equipos Invencibles”.<br /><br />
    Rubén aplica estrategias del deporte de alto rendimiento al mundo empresarial, inspirando a líderes a construir equipos de alto rendimiento.

    </>],
  },{
    nombre: "Marc Selvitelli",
    empresa: "",
    cargo: "",
    img: ponente9,
    url: '',
    semblanza: [<>
    Marc Selvitelli was selected by the NAIOP Board of Directors in 2022 as the association's president and chief executive officer. He is responsible for overseeing the association’s Corporate headquarters based in Herndon, Virginia. Through Selvitelli’s leadership and guidance, the headquarters staff is responsible for serving NAIOP’s 20,000+ members who are involved with the development, ownership and investment of industrial, office, retail and mixed-use properties throughout North America.<br /><br />
    Selvitelli first joined the association in 2006 where he served as NAIOP's vice president for membership and chapter relations until 2014. He returned to NAIOP in 2017 as the executive vice president and chief operating officer where he was responsible for running the association's day-to-day affairs.<br /><br />
    He possesses nearly 30 years of association management experience and has filled several roles with associations in the construction, real estate and engineering industries. Prior to returning to NAIOP in 2017, he worked for SmithBucklin where he served as the executive director of the Restoration Industry Association and the National Association of Local Housing Finance Agencies. His experience also includes roles in government affairs, strategic planning, chapter relations and membership development. <br /><br />
    He is a graduate of the Pennsylvania State University and received his Certified Association Executive (CAE) certification from the American Society of Association Executives. 
    </>],
  },{
    nombre: "Rosanety Barrios",
    empresa: "",
    cargo: "",
    img: ponente10,
    url: '',
    semblanza: [<>
    Rosanety es reconocida como una destacada experta en energía en México, con una trayectoria profesional que abarca más de 20 años en el sector. Durante 15 años, fue parte del sector financiero mexicano y ha ocupado roles clave en la Comisión Reguladora de Energía y la Secretaría de Energía de México entre 2000 y 2018.<br /><br />
    Con una visión integral del sector energético, Rosanety fue parte del equipo encargado del diseño de la reforma constitucional de 2013 en materia eléctrica y del desarrollo legal y regulatorio de la reforma de hidrocarburos, particularmente en los mercados de gas natural, petrolíferos y GLP.<br /><br />
    Como parte del equipo fundador de Voz Experta, AC, dio inicio a una iniciativa que busca visibilizar a las mujeres expertas en el sector energético. Además, es miembro activo del International Women's Forum Capítulo México y contribuye mensualmente en Opinión 51, así como con publicaciones técnicas en México Como Vamos. Actualmente, se desempeña como consultora especializada en política energética y regulación.<br /><br />
    Durante el proceso electoral de 2024, coordinó la mesa de energía para la Coalición Fuerza y Corazón por México.<br /><br />
    Rosanety es licenciada en Finanzas y posee una maestría en Finanzas por la UNAM, así como otra en Regulación Económica por la Universidad de Barcelona. Además, está certificada como consejera independiente por el IPADE.
    </>],
  },{
    nombre: "David Velosa",
    empresa: "",
    cargo: "",
    img: ponente11,
    url: '',
    semblanza: [<>"David Velosa se desempeña como Director de Operaciones Comerciales en Distributed Power Solutions (DPS Energy), donde lidera iniciativas estratégicas para ofrecer soluciones de generación de energía móvil detrás del medidor a diversos mercados globales. Con más de quince años de experiencia, David ha proporcionado con éxito soluciones energéticas fiables y rentables a sectores como la minería, el petróleo y el gas, las aplicaciones industriales, las microrredes y los servicios públicos.<br /><br />
    David tiene una licenciatura en ingeniería eléctrica y una maestría en negocios, con especialización en finanzas. Su experiencia técnica, combinada con un profundo conocimiento de los modelos financieros, le permite evaluar y mitigar el impacto de los déficits de energía en los ingresos operativos y los costos imprevistos. A lo largo de su carrera, David se ha forjado una reputación por desarrollar y ejecutar estrategias innovadoras que ayudan a los clientes a satisfacer sus necesidades energéticas al tiempo que optimizan los costes.<br /><br />
    Su liderazgo en DPS Energy se define por un compromiso con la innovación, la excelencia operativa y el fomento de relaciones a largo plazo con los clientes, asegurando que estén bien preparados para satisfacer las demandas cambiantes del panorama energético".
    </>],
  },{
    nombre: "Carla Ortiz",
    empresa: "",
    cargo: "",
    img: ponente12,
    url: '',
    semblanza: [<>Carla Ortiz es directora general y líder en el desarrollo de energías renovables en México y LATAM. Con más de una década de experiencia en el sector solar, también se desempeña como consejera de ASOLMEX. Es egresada de la Maestría en Energía Renovable de la Universidad de Duke y cuenta con una sólida trayectoria en el sector financiero, lo que le ha permitido impulsar de manera efectiva el crecimiento de energías renovables y la captación de fondos de inversión para proyectos de energía solar y almacenamiento. Su pasión por la sostenibilidad y su liderazgo visionario la convierten en una referente en la transformación energética de la región.</>],
  },{
    nombre: "Altagracia Gómez",
    empresa: "",
    cargo: "",
    img: ponente13,
    url: '',
    semblanza: [null],
  },{
    nombre: "Jesús Muñoz",
    empresa: "",
    cargo: "",
    img: ponente14,
    url: '',
    semblanza: [<>Jesús Muñoz Esteban asume el cargo de Director General de Egis en México a mediados del año 2021 y un año después se confirma en una nueva posición estrategica como CEO de la región LatAm. Su trayectoria profesional se ha enfocado fundamentalmente en actividades directivas en las cuales ha logrado aportar sus conocimientos operativos en el desarrollo de redes de infraestructura de transporte,  mejora de la movilidad urbana y seguridad pública.<br /><br />
      Jesús cuenta con un profundo conocimiento y entendimiento del mercado mexicano.  Ha colaborado en importantes proyectos en Latinoamérica y sus atributos profesionales han sido el de liderar proyectos de alta complejidad, con equipos multiculturales de diferentes continentes.<br /><br />
      Jesús, es ingeniero especializado en ciencias de administracion urbana, egresado de la “École Nationale des Ponts, ParisTech”. Cuenta con un máster en negocios internacionales por la “ICN Business School” de Nancy, Francia, y tiene más de 25 años de experiencia en empresas de talla internacional, en los sectores de medio ambiente, energía y telecomunicaciones.
      </>],
  },{
    nombre: "Ena Gutiérrez",
    empresa: "",
    cargo: "",
    img: ponente15,
    url: '',
    semblanza: [<>Ena ha sido parte del sector de generación distribuida en México desde hace 9 años. Gracias a su liderazgo en el proyecto, Enlight fue la primera empresa en desarrollar su propio sistema de monitoreo, Apollo.<br /><br />
      Ena cuenta con un amplio conocimiento en el desarrollo de proyectos fotovoltaicos y de almacenamiento de energía y debido a ello, se ha dedicado a crear la nueva de negocio de almacenamiento de energía en Enlight. Su rol como Directora de Nuevos Negocios abarca la ejecución del proceso comercial para proyectos fotovoltaicos y de almacenamiento de energía, así como la elaboración del modelo financiero para ofrecer estos proyectos como servicios financiados en el esquema PPA Onsite y SaaS (Storage as a Service).<br /><br />
      Ena es Ingeniera en Desarrollo Sustentable por el Tecnológico de Monterrey y se ha especializado en el desarrollo de negocios enfocado en microrredes y soluciones distribuidas en México y Latinoamérica.
      </>],
  },{
    nombre: "Victor Mejía",
    empresa: "",
    cargo: "",
    img: ponente16,
    url: '',
    semblanza: [<>Víctor Mejía es el Chief Commercial Officer de Energía Real, con más de 14 años de experiencia en el sector de energías renovables. Se graduó en Ingeniería Industrial del Tecnológico de Monterrey y ha estado al frente de proyectos fotovoltaicos, térmicos y de almacenamiento en México. Víctor ha desempeñado roles clave en la operación y comercialización en empresas de renombre, destacándose por su capacidad para liderar equipos y expandir operaciones en mercados competitivos. Ha sido instrumental en la ejecución de proyectos significativos tanto públicos como privados, y activamente involucrado en asociaciones del sector, consolidando su reputación como líder en el crecimiento y la innovación energética.
      </>],
  },{
    nombre: "Adolfo Borja",
    empresa: "",
    cargo: "",
    img: ponente17,
    url: '',
    semblanza: [<>Adolfo Borja es un líder con experiencia en desarrollo de negocios, gestión de activos y proyectos en el sector energético. Su especialidad radica en la identificación e implementación de soluciones energéticas a clientes industriales, así como el desarrollo, ejecución y operación de proyectos de energía eléctrica, tales como la generación distribuida, cogeneración y energías renovables. Ha liderado equipos en empresas tales como IGSA, Northland Power y Enel, entre otros, donde ha sido responsable del P&L de unidades de negocio, desarrollo de proyectos y gestión comercial.<br /><br />
      Con una formación en Ingeniería Civil y una Maestría en Administración, su experiencia en fusiones, adquisiciones, colocación de PPA y refinanciamiento de activos le han permitido destacarse en el sector. Adolfo es reconocido por sus habilidades en liderazgo, estrategia empresarial y transformación digital, lo que lo convierte en un experto capaz de brindar soluciones eficientes en generación local y eficiencia energética.      
      </>],
  },{
    nombre: "Claudia Esteves Cano",
    empresa: "Directora General de AMPIP",
    cargo: "",
    img: ponente18,
    url: '',
    semblanza: [<>Es una figura clave en el desarrollo inmobiliario industrial de México. Con 35 años de experiencia, ha ocupado posiciones estratégicas en el Banco Nacional de Comercio Exterior y ProMéxico, incluyendo su rol como Consejera Comercial de México en Italia, en Alemania, y como Directora Ejecutiva de Estrategia. Participó activamente en la modernización del TLCAN/TMEC, y ha sido conferencista internacional en temas de desarrollo económico, comercio exterior e inversión extranjera en México.<br /><br />
    Graduada en Relaciones Internacionales por la UNAM, diplomada en inversión y gestión de activos industriales por el ITAM, y experta MDI Industrial por la Universidad Politécnica de Madrid. En 2023, fue reconocida como uno de los mejores CEOs en México por Great Place to Work, subrayando su liderazgo en el sector.
    </>],
  },{
    nombre: "David Eaton",
    empresa: "",
    cargo: "",
    img: ponente19,
    url: '',
    semblanza: [<>David Eaton supports CPKC’s critical mission to promote advanced manufacturing and community development in North America. With nearly 25 years at the railroad, Eaton has held multiple leadership roles in Mexico including Vice-President Corporate Affairs, Real Estate, Business Development, Customer Service and Operations. Eaton is currently responsible for business development in Mexico for CPKC. Also, he currently serves as North American Chariman of NASCO and VP of the American Chamber of Commerce (AMCHAM) Northeast Division.<br /><br />
      David is a dedicated Rotarian with 28 years of community service at the Monterrey Metropolitan Rotary club. Eaton has been elected as governor of Rotary district 4130 for the period 2026/2027 responsible for 72 clubs throughout Northeast Mexico. From 2020 to 2022, David was president of the prestigious National Freight Transportation Association (NFTA). David and his wife Veronica live in Monterrey, Mexico with their 3 children. He holds a law degree from the University of Arizona and a Master’s degree in Commercial Law from the Technological Institute of Monterrey (ITESM).
      </>],
  },{
    nombre: "Santiago Villagómez",
    empresa: "",
    cargo: "",
    img: ponente20,
    url: '',
    semblanza: [<>Santiago es CEO y cofundador de Energía Real, una empresa de energía pionera en la venta de energía renovable generada en sitio. Desde su inicio, post-desregulación del sector energético mexicano, Energía Real ha sido líder en PPAs de energía distribuida comercial e industrial. Sus clientes incluyen FIBRAs y fondos inmobiliarios de capital privado internacional, así como algunas de las empresas privadas más grandes de México.<br /><br />
      En 2021, Riverstone adquirió una participación en Energía Real y comprometió capital para crecimiento. El compromiso de capital total permite a Energía Real continuar con su objetivo de desarrollar 500MW de instalaciones solares, cubriendo hasta 4 millones de metros cuadrados de azoteas y compensando más de 400.000 toneladas de CO2 por año.<br /><br />
      Antes de Energía Real, Santiago fue Jefe de Inversiones en Gran Ciudad (encargado de invertir $111 millones en desarrollos inmobiliarios); así como Asociado de Inversiones y Gestión de Activos en MIRA, donde invirtió US$140 millones en 9 transacciones inmobiliarias.<br /><br />
      Desde 2018, Santiago es asesor de Dark Horse Ventures, un fondo de capital de riesgo regional.<br /><br />
      Santiago es Ingeniero Químico, de la Universidad Iberoamericana en la Ciudad de México.</>],
  },{
    nombre: "Antonio Perea",
    empresa: "",
    cargo: "",
    img: ponente21,
    url: '',
    semblanza: [<>Desarrollador de Negocios en Huawei México especializado en sistemas fotovoltaicos (PV) y almacenamiento en baterías (BESS). Cuenta con más de 10 años de experiencia en el sector de energías renovables, apoyando el desarrollo de proyectos de energía solar y almacenamiento con empresas nacionales e internacionales. Ingeniero Industrial del Instituto Tecnológico de Monterrey<br /><br />
      Ha desarrollado un enfoque sólido en temas comerciales, cadenas de valor y análisis financiero para el desarrollo de proyectos
      </>],
  },{
    nombre: "Lyman Daniels",
    empresa: "",
    cargo: "",
    img: ponente22,
    url: '',
    semblanza: [<>Es el presidente de CBRE México desde 2009 y es el ejecutivo responsable de las operaciones en México y Colombia.
      Sus responsabilidades en la empresa incluyen dar dirección estratégica como jefe de la organización de servicios inmobiliarios líder en el mercado de México y Colombia con más de 1,000 empleados que realizan servicios de asesoría y transacciones, valuaciones, loan services y administración de propiedades. CBRE México, bajo su liderazgo, ha sido reconocida como la oficina de más rápido crecimiento orgánico en todo CBRE a nivel global durante más de cinco años.<br /><br />
      Al llegar a México en 1994 para Hines, el desarrollador y gerente de bienes raíces privados, fundó y creció rápidamente un negocio de desarrollo y administración de propiedades / instalaciones en México para incluir asignaciones en edificios de oficinas de clase A multi-inquilino, edificios de instalaciones corporativas, complejos de apartamentos de lujo y parques industriales a gran escala individuales y multi-inquilinos. También supervisó las carteras combinadas de más de 100 propiedades, supervisó el desarrollo de $ 800 millones en inversiones inmobiliarias comerciales y fue responsable de la primera venta de activos múltiples para un fondo de pensiones de los Estados Unidos en México. Ha jugado un papel directo en la institucionalización del mercado de bienes raíces comerciales en toda América Latina trabajando extensamente en Panamá y Colombia.<br /><br />
      Lyman es autor de numerosas publicaciones en la industria de bienes raíces comerciales. Es miembro fundador y fue presidente del Urban Land Institute (ULI) en México. Anteriormente fue Presidente de la Región Internacional de la Asociación de Propietarios y Gerentes (BOMA) durante cinco años consecutivos. Actualmente, forma parte de la junta ejecutiva de la fundación sin fines de lucro del Hospital ABC (American British Cowdray) con sede en la Ciudad de México.      
      </>],
  }
];
const Ponentes = (props) => {
  const ref_carousel = React.createRef();
  const [hover, setHover] = useState(false);
  const [selec_person, setSelectPerson] = useState(0);
  const { idioma } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      
      <CaretRightFilled className={className} style={{ ...style, display: "block", color:'#022435', fontSize:'34px' }} onClick={onClick} />
    );
  }
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <CaretLeftFilled className={className} style={{ ...style, display: "block", color:'#022435', fontSize:'34px', marginLeft:'-10px' }} onClick={onClick} />
    );
  }

  const settingsPonentes = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows:true,
    nextArrow:<SampleNextArrow /> ,
    prevArrow:<SamplePrevArrow  />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
          arrows:true,
          nextArrow:<SampleNextArrow /> ,
          prevArrow:<SamplePrevArrow  />,
        }
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows:true,
          nextArrow:<SampleNextArrow /> ,
          prevArrow:<SamplePrevArrow  />,
        }
      }
    ]
  };
//sort acomoda alfabeticamente
  let ver_ponentes = expositor.sort((a, b) => a.nombre > b.nombre ? 1 : -1).map((seccion, index) => {
    return (
      <>
        <div className="contenedor_imagen_con">
          <img src={seccion.img} className="imagen_con" alt="" />
          <div className="overlay_con">
            <div className="text_con">
              <p className="nombre_con color_blanco bold_font">{seccion.nombre}</p>
              <br />
              <p className="empresa_con color_blanco">{seccion.empresa}</p>
              <p className="cargo_con color_blanco">{seccion.cargo}</p>
              <br />
              <p style={{display:'non',textAlign:'left'}}>
                {seccion.semblanza != '' ?
                <span
                onClick={() => {
                  showModal();
                  setSelectPerson(index);
                }}
                className="btn_desc_con color_blanco"
              >
                <i>Leer semblanza</i>
              </span>
              :
              <></>}
              </p>
            </div>
          </div>
        </div>
        <br />
        </>
    );
  });
  let ver_texto_modal = expositor
    .filter((item, index) => {
      if (selec_person === index) {
        return true;
      } else {
        return false;
      }
    })
    .map((seccion, index) => {
      return (
        <Col xs={24}>
          <p className="nombre_modal">{seccion.nombre}</p>
          <p className="empresa_modal">{seccion.empresa}</p>
          <p className="cargo_modal">{seccion.cargo}</p>
          <br />
          <p className="semblanza_modal">{seccion.semblanza}</p>
          <br />
        </Col>
      );
    });

  return (
    <>
      <Row justify="center" align="middle" className="contenedor_ponentes">
        <Col span={24} offset={0}>
          <p className="textos_titulos_secciones color_azul">CONFERENCISTAS</p><br />
        </Col>
        <Col xs={10} md={16}>
       <Slider ref={ref_carousel} {...settingsPonentes} style={{margin:'0px', padding:'0px'}} className="slide_ponente">
        {ver_ponentes}
        </Slider>
        </Col>
      </Row>
      <Modal
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        title={false}
        closeIcon={<CloseOutlined style={{ color: "#ffffff" }} />}
      >
        {ver_texto_modal}
      </Modal>
    </>
  );
};
export default Ponentes;
