import React, { useEffect, useState } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { Element, scroller } from "react-scroll";

import RecuperarContrasena from "../sesion/RecuperarContrasena";
import Login from "../registro/Login";
import Error from "../auth/Error";
import Home from "../evento/Home";
import Registro from "../registro/Registro";
import RegistroCerrado from "../registro/RegistroCerrado";
import Webinars from "../conferencias/Webinars";
import EstadisticaGrafica from "../conferencias/EstadisticaGrafica";

import Networking from "../networking/Networking";

import Demo from "../en-vivo/Demo";

import { AuthContext } from "../../utils/AuthContext";
import Admin from "../admin/Admin";
import VisualizarPreguntas from "../admin/VisualizarPreguntas";
import PopUpPregunta from "../conferencias/PopUpPregunta";
import CerrarSesion from "../sesion/CerrarSesion";
import Header from "./Header";
import Footer from "./Footer";

import "../../assets/css/general.css";
import Costos from "../costos/Costos";
import RegistroConvencion from "../registro/RegistroConvencion";

export const Master = () => {
  const location = useLocation();
  const [idioma, setIdioma] = useState("es");

  const secciones_auth = [
    //{componente:<Registro tipo_registro="presencial" />, url:'/registro/presencial'},
     /*{
       componente: <Registro tipo_registro="virtual" idioma={idioma} />,
       url: "/registro",
     },*/
    {componente: <RegistroCerrado tipo_registro="virtual" idioma={idioma} />,url: "/registro",},
    {componente: <RegistroConvencion tipo_registro="virtual" idioma={idioma} />,url: "/registro-oculto",},
    //{componente: <Registro tipo_registro="virtual" idioma={idioma} />,url: "/registro-uno",},
    /*{
      componente: <Registro tipo_registro="virtual" idioma={idioma} />,
      url: "/registro",
    },*/
    //{componente:<Registro tipo_registro="virtual" idioma={idioma} /> , url:'/'},
    //{componente:Registro, url:'/'},
    //{componente:Ponentes, url:'/conferencistas'},
    //{ componente: <Entrada idioma={idioma} />, url: "/transmision" },
    //{ componente: <Demo idioma={idioma} />, url: "/transmision" },
    //{componente:RegistroCompleto, url:'/completo'},
    //{ componente: Login, url: "/login" },
    //{componente:Conferencias, url:'/conferencias'},
    //{componente:Patrocinadores, url:'/patrocinadores'},
    //{componente:VideoPatrocinadores, url:'/patrocinadores/:empresa'},
    {componente:<Networking />, url:'/directorio'},
    { componente: <Webinars idioma={idioma} />, url: "/" },
    { componente: <Costos idioma={idioma} />, url: "/costos" },
    
    //{componente:Error, utl:'/*'}
  ];

  const secciones = [
    { componente: Admin, url: "/admin-preguntas" },
    { componente: VisualizarPreguntas, url: "/visualizar-preguntas" },
    { componente: EstadisticaGrafica, url: "/estadisticas-graficas" },
    { componente: CerrarSesion, url: "/cerrar-sesion" },
  ];

  function sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  useEffect(() => {
    if (location.hash === "") {
      scroller.scrollTo("header");
    } else {
      scroller.scrollTo(location.hash);
    }
  });

  useEffect(() => {
    sleep(1000).then(() => {
      if (location.hash === "") {
        scroller.scrollTo("header");
      } else {
        scroller.scrollTo(location.hash);
      }
    });
  }, []);

  let visualizar_secciones_auth = secciones_auth.map((seccion) => {
    if (
      seccion.url === "/registro/presencial" ||
      seccion.url === "/registro/virtual"
    ) {
      return (
        <Route path={seccion.url} exact>
          {seccion.componente}
        </Route>
      );
    }
    return (
      <Route
        path={seccion.url}
        exact
        render={(props) => {
          return seccion.componente;
        }}
      />
    );
  });

  let visualizar_secciones = secciones.map((seccion) => {
    return (
      <Route
        path={seccion.url}
        exact
        component={seccion.componente}
        idioma={idioma}
      />
    );
  });

  return (
    <AuthContext>
      <Element name="header"></Element>
      <Header setIdioma={setIdioma} idioma={idioma} />

      <Switch>
        {visualizar_secciones}
        {visualizar_secciones_auth}

        <Route path="/*" exact component={Error} idioma={idioma} />
      </Switch>
      <Footer />
    </AuthContext>
  );
};
