import React, { useState } from "react";
import { Row, Col, Modal } from "antd";
import { Link } from "react-router-dom";
import "../../assets/css/agenda.css";


import clinica_golf from '../../assets/documentos/Clinica_de_golf.pdf';
import info_pdf from '../../assets/documentos/Torneo_de_golf.pdf';
import agotado from '../../assets/images/segunda_2024/gracias_lugaresagotados_ampip2024.jpg';
import logo1 from '../../assets/images/anual_2024/PATROCINADORES/energiareal.png';

import { CloseOutlined } from "@ant-design/icons";


const Agenda = (props) => {
  const [dia, setDia] = useState(1);
  const [ver_order, setVerOrder] = useState(0);
  const { idioma } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const conferencia = [
    {
      dia: 1,
      hora: "12:00 - 18:00 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Registro</span>],
              cargo: [''],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<span className="color_azul"></span>],
        },
      ],
      back: "",
    }, {
      dia: 1,
      hora: "16:00 - 18:00  Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Sesión Ordinaria del Consejo Directivo AMPIP</span>],
              cargo: [<span></span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<><span className="">Salón Xiriki</span></>],
        },
      ],
      back: "back_dispar",
    }, {
      dia: 0,
      hora: "17:00 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span></span>],
              cargo: [""],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [<></>],
          sub: [""],
          desc: [<span className="">Ingreso del Secretario <strong>Marcelo Ebrard</strong><br />
            para participar en la sesión a puerta cerrada</span>],
        },
      ],
      back: "back_dispar",
    }, {
      dia: 1,
      hora: "18:30 - 22:30 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Cocktail y cena de bienvenida</span>],
              cargo: [""],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<span className="">Jardín Haramara
            </span>],
        },
      ],
      back: "back_dispar",
    }, {
      dia: 2,
      hora: "08:30 - 14:00 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Ciclo de conferencias</span>],
              cargo: [<span></span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<span className="">Salón Tukipa</span>],
        },
      ],
      back: "",
    }, {
      dia: 2,
      hora: "08:30 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span></span>],
              cargo: [<span></span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<span className="">Bienvenida e informe de actividades<br />
            <strong>Jorge Avalos</strong>, Presidente de la AMPIP</span>],
        },
      ],
      back: "",
    }, {
      dia: 2,
      hora: "08:45 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span></span>],
              cargo: [<span></span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<span className=""><strong>Entrega de reconocimientos</strong></span>],
        },
      ],
      back: "",
    }, {
      dia: 2,
      hora: "09:00 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span></span>],
              cargo: [<span></span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<span className="">Realidades del mercado inmobiliario industrial hoy<br />
          <strong>Lyman Daniels</strong>, Presidente CBRE México</span>],
        },
      ],
      back: "",
    },{
      dia: 2,
      hora: "09:45 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span></span>],
              cargo: [<span></span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<span className="">Competitividad de México<br />
          <strong>Rodrigo Pacheco</strong>, Periodista especializado</span>],
        },
      ],
      back: "",
    }, {
      dia: 2,
      hora: "10:45 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Receso</span>],
              cargo: [<span></span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<span className=""></span>],
        },
      ],
      back: "",
    },{
      dia: 2,
      hora: "11:15 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span></span>],
              cargo: [<span></span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<span className="">
            Atlas AMPIP<br />
            <strong>Claudia Esteves</strong>, AMPIP<br />
            <strong>Santiago Villagómez</strong>, Energía Real <br />
            <strong>David Eaton</strong>, CPKC
            </span>],
        },
      ],
      back: "",
    },  {
      dia: 2,
      hora: "12:00 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span></span>],
              cargo: [<span></span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<span className="">Desafíos de la Relación Bilateral México - Estados Unidos <br />
            <strong>Lila Abed</strong>, Director The Wilson Center</span>],
        },
      ],
      back: "",
    }, {
      dia: 2,
      hora: "13:00 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span></span>],
              cargo: [<span></span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<span className="">Geografía para la competitividad <br />
            <strong>Jesús Muñoz</strong>, CEO Latam EGIS</span>],
        },
      ],
      back: "",
    }, {
      dia: 2,
      hora: "13:30 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span></span>],
              cargo: [<span></span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<span className="">Mensaje<br />
          <strong>Marcelo Ebrard</strong>, Secretario de Economía</span>],
        },
      ],
      back: "",
    },  {
      dia: 2,
      hora: "14:15 - 16:00 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span className="color_azul">Comida</span>],
              cargo: [""],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<span className="">Jardín Haramara</span>],
        },
      ],
      back: "",
    },
    {
      dia: 2,
      hora: "15:30 - 19:30 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Tarde libre</span>],
              cargo: [<></>],
            }
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [""],
        },
      ],
      back: "back_dispar",
    },
    {
      dia: 2,
      hora: "20:00 - 23:00 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Cena</span>],
              cargo: [<></>],
            }
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<span className="">Beach Lawn</span>],
        },
      ],
      back: "",
    },

    {
      dia: 3,
      hora: "07:00 - 08:00 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>1ª Carrera con Causa AMPIP 2024</span>],
              cargo: [""],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<span className="">Gold Route (Distancia: 2.4 kms.) Patrocinador: <img src={logo1} style={{ width: '100px' }} /><br />
          Foyer del salón Tukipa</span>],
        },
      ],
      back: "",
    },
    {
      dia: 3,
      hora: "09:30 - 14:00 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Ciclo de conferencias</span>],
              cargo: [""],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<span className="">Salón Tukipa</span>],
        },
      ],
      back: "back_dispar",
    }, {
      dia: 3,
      hora: "09:30 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span></span>],
              cargo: [""],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<span className="">Tendencias y oportunidades en el sector inmobiliario industrial<br />
            <strong>Marc Selvitelli</strong>, Presidente NAIOP</span>],
        },
      ],
      back: "back_dispar",
    }, {
      dia: 3,
      hora: "10:15 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span></span>],
              cargo: [""],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<span className="">Conversando con:<br />
           
            <strong>Altagracia Gómez</strong>, Consejo Asesor Empresarial<br />
            <strong>Claudia Esteves</strong>, Directora General AMPIP</span>],
        },
      ],
      back: "back_dispar",
    },{
      dia: 3,
      hora: "10:45 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span></span>],
              cargo: [""],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<span className="">Conversatorio: “Innovación en suministro energético: el abasto aislado para los Parques Industriales” <br /><br />
            Moderadora:<br />
            <strong>Rosanety Barrios,</strong> Especialista en temas energéticos<br /><br />
            Panelistas:<br />
            <strong>Víctor Mejía</strong>, Energía Real<br />
            <strong>Ena Gutiérrez</strong>, Enlight <br />
            <strong>David Velosa</strong>, DPS Energy<br />
            <strong>Adolfo Borja</strong>, IGSA<br />
            <strong>Carla Ortiz</strong>, RER Energy<br />
            <strong>Antonio Perea</strong>, Huawei México</span>],
        },
      ],
      back: "back_dispar",
    }, {
      dia: 3,
      hora: "12:00 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Receso</span>],
              cargo: [""],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<span className=""></span>],
        },
      ],
      back: "back_dispar",
    }, {
      dia: 3,
      hora: "12:30 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span></span>],
              cargo: [""],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<span className="">Conversatorio “Más allá del Nearshoring: estrategias para atraer Inversión Extranjera Directa"<br />
            <strong>Enrique Lavin</strong>, Managing Director PGIM<br />
            <strong>Gerardo Ramírez</strong>, Exec. V.P. Industrial, JLL<br />
            <strong>Guillermo Espinosa</strong>, Supply Chain DHL </span>],
        },
      ],
      back: "back_dispar",
    }, {
      dia: 3,
      hora: "13:30 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span></span>],
              cargo: [""],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<span className="">Comportamientos de campeón<br />
            <strong>Rubén Duque</strong>, Conferencista internacional</span>],
        },
      ],
      back: "back_dispar",
    },
    /*{
      dia: 3,
      hora: "14:30 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [""],
              cargo: [''],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<span className="">Mensaje de <strong>Alejandro Malagón</strong>, Presidente Concamin</span>],
        },
      ],
      back: "",
    },*/
    {
      dia: 3,
      hora: "14:35 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [""],
              cargo: [''],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<span className="">Cierre del evento<br />
          <strong>Jorge Avalos</strong>, Presidente de la AMPIP</span>],
        },
      ],
      back: "",
    },
    {
      dia: 3,
      hora: "14:40 - 16:00 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Comida</span>],
              cargo: [''],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<span className="">Restaurante Mezquite</span>],
        },
      ],
      back: "",
    },
    {
      dia: 3,
      hora: "",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Fin de la jornada</span>],
              cargo: [""],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [""],
        },
      ],
      back: "back_dispar",
    },
    {
      dia: 4,
      hora: "08:30 – 14:30  Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Torneo de Golf*</span>],
              cargo: [""],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<>La Higuera<br />
            <span className="color_azul_claro_2">Green Fee 18 hoyos $4,800.00</span></>],
        },
      ],
      back: "",
    },
    {
      dia: 4,
      hora: "09:00 - 10:00 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Clínica de Golf*</span>],
              cargo: [""],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<>La Higuera<br />
            <span className="color_azul_claro_2">* Una vez registrado, te contactaremos</span></>],
        },
      ],
      back: "back_dispar",
    },


  ];

  const ordenes = [
    {
      orden: 1, texto: [<>
        <p className="titulo_agenda">Comité de Promoción</p>
        <ol className="desc_agenda" type="1">
          <li>Bienvenida Presidente del Comité – <span className="color_azul_claro_2">José Luis Benitez</span></li>
          <li>Autopresentación de los asistentes</li>
          <li>Presentación de plan de trabajo y acciones relevantes
            <ol type="a">
              <li>Plan de trabajo 2022</li>
            </ol>
          </li>
          <li>Seguimiento y avance de acuerdos
            <ol type="a">
              <li>Información
                <ol type="i">
                  <li>Materiales actualizados</li>
                  <li>Sitio web</li>
                </ol>
              </li>
              <li>Resumen de leads AMPIP</li>
              <li>Ecosistema de promoción AMPIP</li>
              <li>Eventos
                <ol type="i">
                  <li>Promoción con cuerpo diplomático - 20 de octubre 2022</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>Nuevos temas:
            <ol type="a">
              <li>Encuesta sobre percepción y tendencias de la IED en México, 2022 - 2023</li>
            </ol>
          </li>
          <li>Propuestas de eventos y capacitación</li>
        </ol>
      </>]
    },
    {
      orden: 2, texto: [<>
        <p className="titulo_agenda">Comité ASG</p>
        <ol className="desc_agenda" type="1">
          <li>Bienvenida Presidente del Comité – <span className="color_azul_claro_2">Karen Mora</span></li>
          <li>Autopresentación de los asistentes</li>
          <li>Presentación nuevo Secretario Técnico - <span className="color_azul_claro_2">Diego Colunga</span></li>
          <li>Presentación de plan de trabajo y acciones <span className="color_azul_claro_2">relevantes – Karen Mora</span>
            <ol className="q">
              <li>Plan de trabajo 2022</li>
              <li>Acciones relevantes
                <ol type="i">
                  <li>Resumen del Sondeo ASG y Sustentabilidad</li>
                  <li>Adhesión AMPIP al Pacto Mundial</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>Seguimiento y avance de acuerdos
            <ol type="a">
              <li>Perfil de puesto responsable ASG en parques (técnico y directivo) - <span className="color_azul_claro_2">Mario del Bosque</span></li>
              <li>Guía para homologación de conocimientos - <span className="color_azul_claro_2">Carlos Viesca</span></li>
              <li>Directorio - mapa del ecosistema AMPIP - <span className="color_azul_claro_2">Diego Colunga</span></li>
              <li>Reporte de los seminarios:
                <ol>
                  <li>Certificaciones Leed, Well, Edge - <span className="color_azul_claro_2">Karen Mora</span></li>
                  <li>Código de red - <span className="color_azul_claro_2">Diego Colunga</span></li>
                  <li>Programa Nacional de Auditoría Ambiental PROFEPA - <span className="color_azul_claro_2">Rodolfo Morales</span></li>
                </ol>
              </li>
            </ol>
          </li>
          <li>Nuevos temas:
            <ol>
              <li>Presentación proyecto Mariposa Monarca – <span className="color_azul_claro_2">David Eaton</span> - 15 min</li>
              <li>Compromiso social AMPIP – <span className="color_azul_claro_2">Karen Mora</span> – 5 min
                <ol>
                  <li>Se reciben propuestas hasta el 15 de noviembre</li>
                  <li>Reunión noviembre, Comité ASG decide acción social para 2023</li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>
      </>]
    },
    {
      orden: 3, texto: [<>
        <p className="titulo_agenda">Comité de Infraestructura y Seguridad</p>
        <ol className="desc_agenda" type="1">
          <li>Bienvenida Presidente del Comité - <span className="color_azul_claro_2">Daniel Jaimes</span></li>
          <li>Autopresentación de los asistentes</li>
          <li>Seguimiento y avance de acuerdos - <span className="color_azul_claro_2">Lizbeth Contreras</span>
            <ol type="a">
              <li>Reporte general de las reuniones de trabajo del Comité en el periodo mayo-agosto</li>
              <li>Presentación de la nueva versión del documento “Criterios mínimos en seguridad con los que deben contar los parques industriales” (antes Parque Industrial Seguro)</li>
              <li>Presentación del directorio con nuevos consultores expertos en materia de seguridad</li>
              <li>Estatus de la actualización de la Norma Mexicana de Parques Industriales
                <ol type="i">
                  <li>Situación actual del comité (CTNNPI)</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>Logística
            <ol type="a">
              <li>Sugerencias de lineas de acción por parte de los asistentes</li>
              <li>Actividades sugeridas</li>
            </ol>
          </li>
          <li>Propuestas de eventos y capacitación</li>
        </ol>
      </>]
    }
  ]

  let visualizar_agenda = conferencia
    .filter((item) => {
      if (item.dia === dia) {
        return true;
      } else {
        return false;
      }
    })
    .map((da, index) => {
      return (
        <Row

          align="top"
          style={{ padding: "15px 0px", borderBottom: '2px solid #ebf1fc' }}

        >{/**className={da.back} */}
          <Col xs={{ span: 24, offset: 0 }} md={{ span: da.back === 'back_dispar_3' ? 0 : 4, offset: 0 }}>
            {da.hora != '' ? <><p className="hora_agenda">{da.hora}</p><br /></> : <></>}

          </Col>
          <Col xs={{ span: 24, offset: 0 }} md={{ span: da.back === 'back_dispar_3' ? 24 : 17, offset: da.back === 'back_dispar_3' ? 0 : 3 }}>
            {da.actividad.map((datos) => {
              return (
                <>
                  <Row justify="center" align="middle">
                    <Col xs={24} md={24} style={{ paddingLeft: "10px" }}>
                      {datos.personas != "" ? (
                        <>
                          {datos.personas.map((item) => {
                            return (
                              <>
                                <Row align="middle">
                                  <Col xs={24} md={24}>
                                    <p className="nombre_agenda">
                                      {item.nombre}
                                    </p>
                                    <p className="cargo_nombre">{item.cargo}</p>
                                    {datos.desc != "" && (
                                      <p className="desc_agenda">{datos.desc}</p>
                                    )}
                                  </Col>
                                </Row>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </>
              );
            })}
          </Col>
        </Row>
      );
    });

  let ver_texto_modal = ordenes
    .filter((item) => {
      if (ver_order === item.orden) {
        return true;
      } else {
        return false;
      }
    })
    .map((seccion, index) => {
      return (
        <Col xs={24}>
          {seccion.texto}
        </Col>
      );
    });

  return (
    <Row className="back_titulo_agenda_">
      <Col span={20} offset={2}>
        <Row justify="center" align="middle" style={{ position: "" }}>
          <Col
            span={24}
            offset={0}
            style={{ position: "", width: "100%", paddingTop: '3%' }}
          >
            <p className="textos_titulos_secciones color_azul">PROGRAMA</p>
          </Col>
        </Row>
      </Col>

      <Col xs={{ span: 24, offset: 0 }} md={{ span: 16, offset: 4 }}>
        <p className="txt_cabos_agenda color_azul">
          <strong>HORARIO </strong> CDMX
        </p><br />
        <p className="txt_cabos_agenda color_azul_claro">
          Código de vestimenta: <strong>casual de negocios</strong>
        </p>
        <br />
      </Col>
      <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }}>
        <Row justify="space-around">
          <Col
            xs={{ span: 11, offset: 0 }}
            md={{ span: 6, offset: 0 }}
            onClick={() => setDia(1)}
            className={"espacio_dias"
            }
          >
            <div className={dia === 1 ? "back_azul_claro puntero forma_agenda" : "back_blanco forma_agenda puntero"}>
              <p className={dia === 1 ? "color_blanco tex_agenda_dia" : "color_azul tex_agenda_dia"}>
                <strong>20</strong>
                <br />
                <span className={dia === 1 ? "color_blanco fecha_text_agenda" : "color_azul fecha_text_agenda"}>NOVIEMBRE, 2024</span>
              </p>
            </div>
          </Col>
          <Col
            xs={{ span: 11, offset: 0 }}
            md={{ span: 6, offset: 0 }}
            onClick={() => setDia(2)}
            className={"espacio_dias"
            }
          >
            <div className={dia === 2 ? "back_azul_claro puntero forma_agenda" : "back_blanco forma_agenda puntero"}>
              <p className={dia === 2 ? "color_blanco tex_agenda_dia" : "color_azul tex_agenda_dia"}>
                <strong>21</strong>
                <br />
                <span className={dia === 2 ? "color_blanco fecha_text_agenda" : "color_azul fecha_text_agenda"}>NOVIEMBRE, 2024</span>
              </p>
            </div>
          </Col>
          <Col
            xs={{ span: 11, offset: 0 }}
            md={{ span: 6, offset: 0 }}
            onClick={() => setDia(3)}
            className={"espacio_dias"
            }
          >
            <div className={dia === 3 ? "back_azul_claro puntero forma_agenda" : "back_blanco forma_agenda puntero"}>
              <p className={dia === 3 ? "color_blanco tex_agenda_dia" : "color_azul tex_agenda_dia"}>
                <strong>22</strong>
                <br />
                <span className={dia === 3 ? "color_blanco fecha_text_agenda" : "color_azul fecha_text_agenda"}>NOVIEMBRE, 2024</span>
              </p>
            </div>
          </Col>
          <Col
            xs={{ span: 11, offset: 0 }}
            md={{ span: 6, offset: 0 }}
            onClick={() => setDia(4)}
            className={"espacio_dias"
            }
          >
            <div className={dia === 4 ? "back_azul_claro puntero forma_agenda" : "back_blanco forma_agenda puntero"}>
              <p className={dia === 4 ? "color_blanco tex_agenda_dia" : "color_azul tex_agenda_dia"}>
                <strong>23</strong>
                <br />
                <span className={dia === 4 ? "color_blanco fecha_text_agenda" : "color_azul fecha_text_agenda"}>NOVIEMBRE, 2024</span>
              </p>
            </div>
          </Col>

        </Row><br />
      </Col>
      <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }}>
        {dia === 4 ? <><p className="txt_cabos_agenda color_azul"><strong>CLUB DE GOLF LA HIGUERA</strong><br /></p>
          <Row justify={'center'} style={{ display: 'none' }}>
            <Col xs={24} md={24}>
              <img src={agotado} className="img_100" />
            </Col>
          </Row></> : <></>}
        {visualizar_agenda}

        <br />
        <br />
      </Col>
      <Col xs={{ span: 22, offset: 1 }} md={{ span: 16, offset: 4 }} style={{ display: 'none' }}>
        <p className="txt_cabos_agenda color_azul">
          <strong>HORARIO </strong> CDMX / <strong>CÓDIGO DE VESTIMENTA:</strong> CASUAL DE NEGOCIOS
        </p><br /><br />
      </Col>

      <Modal
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        title={false}
        closeIcon={<CloseOutlined style={{ color: "#ffffff" }} />}
      >
        {ver_texto_modal}
      </Modal>
    </Row>
  );
};
export default Agenda;
